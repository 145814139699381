body.about {
  .content {
    margin-top: $spacing-800;
  }

  .profile {
    height: 385px;
    background-size: cover;
    background-position: center;
  }
}