body.home {
  .panel {
    &.signup {
      height: 345px;
      padding: $spacing-800 $spacing-500 $spacing-500;
      display: none;
      flex-direction: column;
      justify-content: space-between;
      align-items: right;
      background: white;

      @media screen and (min-width: $screen-md) {
        display: flex;
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 63%;
      }

      input[type="text"] {
        width: 100%;
        padding: $spacing-200 $spacing-300;
        font-size: $font-size-200;
        border: 1px solid $black-300;
        border-radius: 3px;
      }

      .submit-wrapper {
        text-align: right;
      }
    }
  }

  .pagination-list {
    margin: $spacing-400 auto 0;
    text-align: center;
    font-family: $font-family-base;
    color: $font-color;
    font-size: $font-size-200;

    span {
      display: inline-block;
      margin: 0 $spacing-base;

      a {
        color: $font-color;
        text-decoration: underline;
      }
    }
  }
}