.btn {
  background: $tan-400;
  border: none;
  color: white;
  font-size: 20px;
  font-family: $font-family-base;
  font-weight: 400;
  padding: 6px 30px;
  transition: background 400ms;

  &:hover {
    color: white;
    background: $tan-500;
  }
}