body.contact {
  .content {
    margin-top: 0;

    @media screen and (min-width: $screen-sm) {
      margin-top: 40px;
    }
  }

  p.last {
    margin-bottom: $spacing-1200;
  }

  form {
    input:not([type="submit"]), textarea {
      width: 100%;
      padding: $spacing-300;
      border: none;
      border-top: $spacing-base solid $tan-100;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
      margin-bottom: $spacing-500; 
    }

    textarea {
      min-height: 145px;
    }

    input[type="submit"] {
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    }
  }
}