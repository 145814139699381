.component.card {
  height: 345px;
  width: 100%;
  border: none;
  border-radius: 0;
  background: none;

  &:hover .inner-card {
    text-decoration: none;
    transform: rotateY(180deg);
  }

  .inner-card {
    height: 345px;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 400ms;
    border-top: 8px solid $tan-100;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
  }

  .face {
    border: none;
    border-radius: 0;
    padding: $spacing-500;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  .back {
    transform: rotateY(180deg);
    background-size: cover;
    background-position: center;
    filter: grayscale(1);

    .filter {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background: rgba(0, 0, 0, .4);
      z-index: -1;
    }

    p, h2, {
      font-size: $font-size-200;
      line-height: 24px;
      max-height: 168px;
      font-weight: 500;
      color: white;
      overflow: hidden;
    }

    blockquote {
      display: none;
    }

    .read-more {
      color: white;
    }
  }

  .front {
    .read-more {
      color: $tan-200;

      .chevrons {
        display: inline-block;
        margin-left: 2px;
        color: inherit;
      }

      i {
        font-size: 15px;
        color: inherit;

        &:last-child {
          margin-left: -2px;
        } 
      }
    }
  }
  
  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 25px);
  }

  .read-more {
    font-size: $font-size-200;

    a {
      color: inherit;
    }
  }
}