.layout.blog {
  .hero-image {
    width: 100%;
    height: 355px;
    background-size: cover;
    background-position: center;
    margin-top: 0;

    @media screen and (min-width: $screen-md) {
      margin-top: $spacing-800;  
      height: 400px;    
    }
  }

  .tag-display {
    font-family: $font-family-header;
    font-size: $font-size-200;
    font-weight: 300;
    margin: $spacing-1000 0 0;
    color: $tan-300;

    a {
      color: $tan-300;
    }
  }

  .title {
    margin-top: $spacing-700;
  }

  date {
    margin-top: $spacing-500;
    margin-bottom: $spacing-600;
    display: block;
  }

  img {
    width: 100%;
  }

  p {
    font-size: $font-size-200;
  }

  .caption {
    font-size: $font-size-base;
    color: $black-300;
    font-style: italic;
  }

  hr {
    border-width: 1px;
    border-color: $tan-400;
    max-width: 150px;
  }

  h1:not(.title), h2, h3, h4 {
    margin-top: $spacing-800;
    font-weight: 300;
  }

  ul {
    position: relative;
    font-size: $font-size-100;

    li {
      font-family: $font-family-base;
      list-style: none;

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        background-color: $black-100;
        left: 15px;
        margin-top: 12px;
        transform: rotate(45deg);
      }
    }
  }

  .section-tag {
    text-align: center;
    font-weight: 200;
    font-size: $font-size-400;
    margin-top: $spacing-1000;
    margin-bottom: $spacing-600;
  }

  .grid {
    @media screen and (min-width: $screen-sm) {
      justify-content: center;
    }

    @media screen and (min-width: $screen-md) {
      grid-template-columns: repeat(auto-fit, 49%);
      justify-content: space-between;
    }
  }

  .signup { 
    padding: $spacing-500;

    form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input[type="text"] {
        padding: $spacing-base $spacing-200;
        font-size: $font-size-200;
        border-radius: 3px;
        font-family: $font-family-header;
        border: 1px solid rgba(0, 0, 0, .25);
        width: 100%;
        margin-right: $spacing-500;
      }
    }
  }

  .disqus-wrapper {
    margin-top: 80px;
  }
}