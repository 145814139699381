.nav-wrapper {
  height: 250px;
  transition: height 400ms;
}

nav#main-nav {
  padding: $spacing-400;
  z-index: 3;
  background: white;
  transition: box-shadow 400ms, opacity 400ms;
  min-height: 100px;
  display: none;
  opacity: 0;

  @media screen and (min-width: $screen-md) {
    display: block;
    opacity: 1;
  }

  &.fixed {
    position: fixed;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    padding-bottom: $spacing-500;

    .social-nav {
      display: none;
    }

    .main-nav {
      top: $spacing-600;
    }

    .logo {
      overflow: hidden;

      a {
        display: inline-block;
      }

      img {
        display: none;
        top: 5px;
        width: 100%;
        max-width: 240px;
        z-index: 1;
        position: relative;
        left: 0;

        @media screen and (min-width: $screen-xs) {
          display: block;
        }
      }
      .color-block {
        top: 0px;
        width: $spacing-1200;
        height: 64px;
        left: 0;

        @media screen and (min-width: $screen-xs) {
          top: $spacing-base;
          width: 85px;
          height: 77px;
          left: 100px;
        }
      }
      .title {
        font-size: 30px;
        padding: $spacing-base;
        background: white;
        top: 12px;
        position: absolute;
        z-index: 1;
        line-height: 30px;
        left: 0;

        @media screen and (min-width: $screen-xs) {
          top: $spacing-base;
          font-size: 35px;
          padding: 16px;
          line-height: 45px;
          left: 170px;
        }

        @media screen and (min-width: $screen-sm) {
          font-size: 45px;
        }
      }
      .subtitle {
        display: none;
      }
    }
  }

  .logo {
    a {
      &:hover {
        text-decoration: none;
      }
    }

    img {
      width: 100%;
      max-width: 410px;
      z-index: 1;
      position: relative;
      transition: max-width 400ms;
    }

    .color-block {
      height: 77px;
      width: 100%;
      max-width: 325px;
      background: $tan-100;
      position: absolute;
      top: 55px;
      left: 100px;
      z-index: 0;
      transition: height 400ms, width 400ms, top 400ms;
    }

    .title {
      font-size: 50px;
      left: 170px;
      position: absolute;
      top: 132px;
      line-height: 60px;
      width: 185px;
      transition: top 400ms;

      @media screen and (min-width: $screen-xs) {
        width: 235px;
      }

      @media screen and (min-width: $screen-sm) {
        width: 325px;
      }
    }

    .subtitle {
      left: 170px;
      top: 192px;
      position: absolute;
      font-size: 25px;
      line-height: 30px;
      width: 300px;
    }
  }

  .social-nav {
    line-style: none;
    position: absolute;
    right: 16px;
    top: $spacing-1000;
    cursor: pointer;
    display: none;

    li {
      display: inline-block;
      font-size: 22px;
      opacity: .55;
      margin-left: 2px;
      transition: opacity 400ms;

      &:hover {
        opacity: 1;
      }
    }

    @media screen and (min-width: $screen-md) {
      display: block;
    }
  }

  .main-nav {
    list-style: none;
    display: none;
    position: absolute;
    right: 16px;
    top: 125px;
    transition: top 400ms;

    li {
      display: inline-block;
      font-size: 24px;
      margin-left: $spacing-600;

      &.active {
        &::before {
          content: '';
          position: absolute;
          width: 25px;
          height: 25px;
          background: $tan-100;
          margin-top: -5px;
          margin-left: -10px;
        }
      }

      a {
        color: inherit;
        position: relative;

        &:hover {
          text-decoration: none;
        }
      }
    }

    @media screen and (min-width: $screen-md) {
      display: block;
    }
  }

  #mobile-nav-button {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: $spacing-base;
    font-size: $font-size-500;

    @media screen and (min-width: $screen-xs) {
      top: 16px; 
    }

    @media screen and (min-width: $screen-md) {
      display: none;
    }
  }
}

#mobile-nav {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  display: none;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: opacity 400ms;

  #close-mobile {
    cursor: pointer;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      font-size: $font-size-400;
      font-weight: 300;
      font-family: $font-family-header;
      margin-bottom: $spacing-200;

      a {
        color: inherit;
      }

      > ul {
        padding-left: 0;

        li {
          display: inline-block;
        }
      }
    }
  }
}