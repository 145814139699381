.component.search {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (min-width: $screen-sm) {
    justify-content: space-around;    
  }

  .rounded {
    border-radius: 25px !important;
    padding: 4px $spacing-500 8px;
    font-size: 20px;
    box-shadow: none;
    border: 1px solid $black-300;
    position: relative;

    &:focus {
      outline: none;
    }
  }

  .search-container {
    position: relative;

    &::before {
      content: '';
      width: $spacing-400;
      height: $spacing-400;
      display: block;
      background-image: url(/assets/img/ico-search.jpg);
      background-size: cover;
      z-index: 1;
      position: absolute;
      top: 14px;
      left: $spacing-300;
    }

      input {
        width: 100%;
        max-width: 240px;
        padding-left: $spacing-800;

        @media screen and (min-width: $screen-md) {
          max-width: 325px;
        }
      }
  }

  .filters {
    display: inline-block;
    color: #8e8e8e;
    margin-right: $spacing-300;
    width: 130px;

    @media screen and (min-width: $screen-md) {
      margin-right: $spacing-400;
    }

    i {
      top: $spacing-200;
      right: $spacing-500;
      position: absolute;
      color: $black-100;
      font-size: 16px;
    }
  }
}