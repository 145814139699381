@import 'variables';
@import 'type';
@import 'buttons';
@import 'nav';
@import 'footer';

// COMPONENTS
@import 'components/search';
@import 'components/card';

// PAGES
@import 'pages/about';
@import 'pages/contact';
@import 'pages/home';

// LAYOUTS 
@import 'layouts/blog';

* {
  color: $black-100;
}

body {
  transition: background 400ms;

  &.color-bg {
    background: $tan-100;

    .component.card {
      .inner-card {
        border-top: none;
      }
    }

    .panel {
      border-top: none;
    }
  }
}

.panel {
  border: none;
  width: 100%;
  border-top: 8px solid $tan-100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
}

.title-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing-800;

  @media screen and (min-width: $screen-sm) {
    flex-direction: row;
  }

  h1 {
    margin-bottom: $spacing-600;

    @media screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }
  }

  &.right {
    display: block;

    .search {

      @media screen and (min-width: $screen-sm) {
        float: right;
      }
    }
  }
}

.cards {
  margin-top: $spacing-800;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 90%);
  justify-content: center;

  article {
    left: 0;
    right: 0;
    margin: 0 auto $spacing-400;
  }

  @media screen and (min-width: $screen-sm) {
    grid-template-columns: repeat(auto-fit, 325px);
    justify-content: space-between;
  }

  @media screen and (min-width: $screen-md) {
    grid-template-columns: repeat(auto-fit, 32%);

    &.two-entries {
      justify-content: flex-start;
      grid-template-columns: repeat(auto-fit, 32%);
      gap: 32px;
    }
  }

  @media screen and (min-width: $screen-lg) {
    grid-template-columns: repeat(auto-fit, 350px);
  }
}