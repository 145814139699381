h1, h2, h3, h4, h5 {
  font-family: fira-sans, sans-serif;
  font-style: normal;
  font-weight: 400;
}

h1 {
  font-size: 30px;
  font-weight: 300;
}

h2 {
  font-size: 26px;
}

p, input {
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
}

p {
  a {
    color: inherit;
    text-decoration: underline;
    transition: color 400ms;

    &:hover {
      color: $tan-200;
    }
  }

  &.large {
    font-size: $font-size-200;
    line-height: 28px;
    font-weight: 300;
  }

  strong {
    font-weight: bold;
  }
}

blockquote {
  padding-left: $spacing-600;
  border-left: 4px solid $tan-100;
  margin: $spacing-600 0;
  font-size: $font-size-200;
  font-weight: 200;
}

.attribution {
  font-size: $font-size-base;
  margin-top: $spacing-400;
}

hr {
  border-color: $tan-300;
  border-width: 2px;
  max-width: 240px;
  margin: 40px auto;
}

date {
  font-family: fira-sans, sans-serif;
  font-weight: 300;
  font-size: 22px;
}

.fira-sans {
  font-family: fira-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.alegreya {
  font-family: alegreya-sans-sc, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.acumin {
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
}