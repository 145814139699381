footer {
  font-family: $font-family-header;
  font-weight: 300;
  margin-top: 60px;
  margin-bottom: 15px;
  
  .content {
    text-align: center;
  }

  span {
    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: inherit;
      }
    }

    &:first-child {
      float: left;

      @media screen and (min-width: $screen-sm) {
        position: absolute;
        margin: 0 auto;
        float: none;
      }
    }

    &:last-child {
      float: right;
    }
  }
}